import $ from 'jquery';
import Parallax from './parallax.js';
import ImagesLoaded from 'imagesloaded';

export default () => {
    const isString = (str) => typeof str === 'string';
    const scrollPosition = () => {
        return parseInt(window.scrollY || window.pageYOffset);
    };

    const isValidSelector = (selector) => {
        if (!isString(selector)) return false;
        try {
            $(selector);
        } catch (error) {
            return false;
        }
        return true;
    };

    const isSamePage = (href) => {
        let urlObj;
        try {
            urlObj = new URL(href);
        } catch (_) {
            return true;
        }

        return !(window.location.hostname !== urlObj.hostname || window.location.pathname !== urlObj.pathname);
    };

    function scrollToElement(element) {
        Parallax.update();

        // If the target element is part of the first section of a page, we must not use this as the closest section,
        // because the first section also contains the page header, which would result in scrolling to the top of the
        // page header instead of the target element.
        const closestSection = element.closest('.section__wrapper:not(:first-of-type)');
        const headerHeight = document.querySelector('header').offsetHeight;
        let closestSectionCalculatedMarginTop = 0;

        if (closestSection) {
            closestSectionCalculatedMarginTop = Parallax.simulate(closestSection);
        }

        const targetOffsetTop = Math.floor(element.getBoundingClientRect().top) + scrollPosition() + closestSectionCalculatedMarginTop - headerHeight - 100;

        $('html, body').animate(
            {
                scrollTop: targetOffsetTop,
            },
            500
        );
    }

    const urlHash = window.location.hash;
    if (urlHash && urlHash !== '') {
        // Prevent scroll
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 1);

        const hashIndex = urlHash.indexOf('#');
        if (hashIndex !== -1) {
            const hash = urlHash.substring(hashIndex);
            if (isValidSelector(urlHash) && isSamePage(urlHash)) {
                if ($(urlHash).length && urlHash !== '#wlymmenu' && urlHash !== '#') {
                    ImagesLoaded(document, () => {
                        scrollToElement(document.querySelector(urlHash));
                    });
                }
            }
        }
    }

    $(document).on('click', 'a[href]:not([href^="mailto:"], [href$="#"])', function (event) {
        const href = $(this).attr('href');
        const hashIndex = href.indexOf('#');

        if (hashIndex !== -1) {
            const hash = href.substring(hashIndex);
            if (isValidSelector(hash) && isSamePage(href)) {
                if ($(hash).length && hash !== '#wlymmenu' && hash !== '#') {
                    event.preventDefault();

                    scrollToElement(document.querySelector(hash));
                }
            }
        }
    });
};
