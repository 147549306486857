// Import polyfills
import './js/polyfills';
import './js/libraries';
import './js/scripts';

import { RunThemeExtensions } from 'wly-theme-extensions';

import Paginator from '@/js/vue/components/Paginator.vue';
import YoutubePlayer from '@/js/vue/components/YoutubePlayer.vue';
import SearchPage from '@/js/vue/components/SearchPage.vue';
import Slider from '@/js/vue/components/Slider.vue';
import { ContainerBoundaries } from '@/js/alpine/ContainerBoundaries';

import PrimeVue from 'primevue/config';

RunThemeExtensions({
  vue: {
    components: {
      'wly-paginator': Paginator,
      'wly-youtube': YoutubePlayer,
      'wly-search-page': SearchPage,
      'wly-slider': Slider,
    },
    onBootstrap: (app) => {
      app.use(PrimeVue, { ripple: true });
    },
  },
  alpine: {
    components: {
      ContainerBoundaries,
    },
  },
});

// Import styles
// Should be last to override vue component styles more easily
import './sass/style.scss';
