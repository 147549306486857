<template>
  <div class="slider-swiper" ref="slider">
    <div class="swiper-wrapper">
      <slot></slot>
    </div>

    <div class="swiper-button-prev teaser__arrow">
      <IconLeft />
    </div>
    <div class="swiper-button-next teaser__arrow">
      <IconRight />
    </div>
    <div class="swiper-pagination"></div>
  </div>
</template>

<script lang="ts" setup>
import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';
import { onMounted, ref, nextTick } from 'vue';
import IconLeft from '../../../icons/icon-chevron-left.svg?component';
import IconRight from '../../../icons/icon-chevron-right.svg?component';


const props = defineProps({
  config: {
    type: Object,
    default: () => {},
  },
});

const slider = ref();
const scrollbar = ref();

onMounted(() => {
  nextTick(
    () =>
      new Swiper(slider.value, {
        modules: [Navigation, Autoplay, Pagination],
        // Optional parameters
        direction: 'horizontal',
        //loop: false,
        speed: 1400,
        slidesPerView: 1,
        spaceBetween: 30,
        breakpoints: {
          1201: {
            spaceBetween: 50,
          },
          993: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 2,
          },
        },
        grabCursor: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        scrollbar: {
          el: scrollbar.value,
        },
        ...props.config,
      })
  );
});
</script>
