<template>
  <div class="paginator-posts-filter">
    <PostsFilter :paginator="paginator">
      <template v-slot:default="{ taxonomies, toggle, selectedTerms, loading, paginator }">
        <template v-for="taxonomyOption in taxonomies" :key="taxonomyOption.slug">
          <Dropdown
            v-if="taxonomyOption.options?.length"
            v-model="selectedTerms[taxonomyOption.slug]"
            :options="filteredOptions(taxonomyOption.options).value"
            optionLabel="label"
            optionValue="id"
            optionDisabled="disabled"
            :loading="loading"
            :disabled="loading"
            :placeholder="taxonomyOption.label"
            @change="toggle(taxonomyOption.slug, $event.value)"
          />
        </template>
      </template>
    </PostsFilter>
  </div>
</template>

<script lang="ts" setup>
import { PostsFilter } from 'wly-theme-extensions';
import Dropdown from '@/js/vue/components/Dropdown.vue';
import { computed } from 'vue';

defineProps(['paginator']);

const filteredOptions = (options: any[]) =>
  computed(() => {
    return options
      .filter((o) => !o.disabled)
      .sort((a,b) => a.label.localeCompare(b.label));
  });
</script>

<style lang="scss">
.paginator-posts-filter {
  display: flex;
  justify-content: center;
  gap: 50px;
  flex-wrap: wrap;
  @include media(XL1200) {
    gap: 0;
    margin-left: -15px;
    margin-right: -15px;
  }
  @include media(M) {
    gap: 15px;
  }
}

.wte-dropdown-options {
  @include media(XL1200) {
    margin: 0 15px;
  }
}

.wte-dropdown {
  width: 325px;
  max-width: 100%;
  @include media(XL1200) {
    width: 33.333%;
    padding-left: 15px;
    padding-right: 15px;
  }
  @include media(M) {
    width: 100%;
  }
}
</style>
