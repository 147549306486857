export const updateActiveItem = (navigationId = 'headerNavbar') => {
    const nav = document.querySelector('#' + navigationId);

    if (!nav) {
        return;
    }

    const activeItems = nav.querySelectorAll('[class*=active]');
    activeItems.forEach((el) => el.classList.remove('active', 'active-children'));

    const path = window.location.pathname.replace(/\/$/, "");

    const link = nav.querySelector([
        '[href="' + window.location.origin + path + '"]',
        '[href="' + window.location.origin + path + '/"]',
        '[href="' + path + '"]',
        '[href="' + path + '/"]',
    ].join(','));


    if (!link) {
        return;
    }

    // 1st Level
    link.closest('.nav-item.nav-menu')?.classList.add('active-children');

    // 2nd Level
    link.closest('ul')?.parentElement?.classList.add('active-children');

    // Current Link Item
    link.parentElement?.classList.remove('active-children');
    link.parentElement?.classList.add('active');
};

