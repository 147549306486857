import { AlpineComponent, debounce } from 'wly-theme-extensions';

export class ContainerBoundaries extends AlpineComponent {
    init() {
        const listener = debounce(() => this.updateVariables(), 50);

        void listener();

        window.addEventListener('resize', listener);
    }

    updateVariables() {
        const rect = this.$el.getBoundingClientRect();

        this.$el.style.setProperty('--rect-bounding-left', `${rect.left}px`);
        this.$el.style.setProperty('--rect-bounding-right', `${window.innerWidth - rect.right}px`);
        // this.$el.style.setProperty('--rect-bounding-top', `${rect.top}px`);
        // this.$el.style.setProperty('--rect-bounding-bottom', `${rect.bottom}px`);
    }
}