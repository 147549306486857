import $ from 'jquery';

export default (container?: HTMLElement) => {
    // Akkordeon
    $('.wly-accordeon__title').click(function () {
        $(this).parent().toggleClass('active');
        $(this).parent().siblings('.wly-accordeon__item').removeClass('active');

        $('.wly-accordeon__item').each(function () {
            if ($(this).hasClass('active')) {
                $(this).children('.wly-accordeon__content').slideDown();
            } else {
                $(this).removeClass('active');
                $(this).children('.wly-accordeon__content').slideUp();
            }
        });
    });
};
