import ImagesLoaded from 'imagesloaded';
import Masonry from 'masonry-layout';

const wrappers = document.querySelectorAll('.wly-masonry');

function initMasonry(el) {
    return new Masonry(el, {
        itemSelector: '.wly-masonry-item'
    });
}

function rerender(masonry) {
    if (!masonry) {
        return;
    }

    masonry.layout();
}

for (let node of wrappers) {
    let masonry = null;

    ImagesLoaded(node, () => {
        masonry = initMasonry(node);
    });

    document.addEventListener('lazyloaded', function (e) {
        rerender(masonry);
    });
}
